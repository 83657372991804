/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  state: "hover" | "selected" | "default";
  className: any;
  text: string;
  divClassName: any;
}

export const Link = ({ state, className, text = "About us", divClassName }: Props): JSX.Element => {
  return (
    <div
      className={`inline-flex items-center gap-[8px] pt-0 pb-[4px] px-0 justify-center relative ${
        ["hover", "selected"].includes(state) ? "[border-bottom-style:solid]" : ""
      } ${["hover", "selected"].includes(state) ? "border-[#000000]" : ""} ${
        ["hover", "selected"].includes(state) ? "border-b-[0.5px]" : ""
      } ${className}`}
    >
      <div
        className={`font-dekstop-link-regular-24 w-fit tracking-[var(--dekstop-link-regular-24-letter-spacing)] text-[length:var(--dekstop-link-regular-24-font-size)] [font-style:var(--dekstop-link-regular-24-font-style)] text-[#0e0e0e] relative font-[number:var(--dekstop-link-regular-24-font-weight)] text-center whitespace-nowrap leading-[var(--dekstop-link-regular-24-line-height)] ${
          state === "default" ? "mt-[-1.00px]" : "mt-[-0.50px]"
        } ${divClassName}`}
      >
        {text}
      </div>
    </div>
  );
};

Link.propTypes = {
  state: PropTypes.oneOf(["hover", "selected", "default"]),
  text: PropTypes.string,
};
