/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const Envelope = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="24"
      viewBox="0 0 25 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2256 4.5H3.22559C3.02667 4.5 2.83591 4.57902 2.69526 4.71967C2.5546 4.86032 2.47559 5.05109 2.47559 5.25V18C2.47559 18.3978 2.63362 18.7794 2.91493 19.0607C3.19623 19.342 3.57776 19.5 3.97559 19.5H20.4756C20.8734 19.5 21.2549 19.342 21.5362 19.0607C21.8176 18.7794 21.9756 18.3978 21.9756 18V5.25C21.9756 5.05109 21.8966 4.86032 21.7559 4.71967C21.6153 4.57902 21.4245 4.5 21.2256 4.5ZM12.2256 12.4828L5.15402 6H19.2971L12.2256 12.4828ZM9.47965 12L3.97559 17.0447V6.95531L9.47965 12ZM10.5896 13.0172L11.7146 14.0531C11.853 14.1801 12.034 14.2506 12.2218 14.2506C12.4097 14.2506 12.5907 14.1801 12.729 14.0531L13.854 13.0172L19.2915 18H5.15402L10.5896 13.0172ZM14.9715 12L20.4756 6.95438V17.0456L14.9715 12Z"
        fill="white"
      />
    </svg>
  );
};
