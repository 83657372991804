/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  color: string;
  className: any;
}

export const Envelope1 = ({ color = "black", className }: Props): JSX.Element => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 5.38037H3C2.80109 5.38037 2.61032 5.45939 2.46967 5.60004C2.32902 5.74069 2.25 5.93146 2.25 6.13037V18.8804C2.25 19.2782 2.40804 19.6597 2.68934 19.941C2.97064 20.2223 3.35218 20.3804 3.75 20.3804H20.25C20.6478 20.3804 21.0294 20.2223 21.3107 19.941C21.592 19.6597 21.75 19.2782 21.75 18.8804V6.13037C21.75 5.93146 21.671 5.74069 21.5303 5.60004C21.3897 5.45939 21.1989 5.38037 21 5.38037ZM12 13.3632L4.92844 6.88037H19.0716L12 13.3632ZM9.25406 12.8804L3.75 17.9251V7.83568L9.25406 12.8804ZM10.3641 13.8976L11.4891 14.9335C11.6274 15.0605 11.8084 15.131 11.9963 15.131C12.1841 15.131 12.3651 15.0605 12.5034 14.9335L13.6284 13.8976L19.0659 18.8804H4.92844L10.3641 13.8976ZM14.7459 12.8804L20.25 7.83475V17.926L14.7459 12.8804Z"
        fill={color}
      />
    </svg>
  );
};

Envelope1.propTypes = {
  color: PropTypes.string,
};
