import React from "react";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { Label } from "../../components/Label";
import { List } from "../../components/List";
import { ListWrapper } from "../../components/ListWrapper";
import { StateDefaultSizeS } from "../../components/StateDefaultSizeS";
import { Envelope1 } from "../../icons/Envelope1";
import { Facebooklogo } from "../../icons/Facebooklogo";
import { Logo5 } from "../../icons/Logo5";

export const KrititLandingPage = (): JSX.Element => {
  return (
    <div className="bg-[#ffffff] flex flex-row justify-center w-full">
      <div className="bg-[#ffffff] w-[1440px] h-[6412px]">
        <div className="relative h-[6412px]">
          <img className="h-[2312px] top-[3384px] absolute w-[1440px] left-0" alt="Line" src="https://res.cloudinary.com/daqxbdqwb/image/upload/v1717086764/CrititLandingPageImageFolder/line-11_lj80fk.png" />
          <img className="h-[1429px] top-0 absolute w-[1440px] left-0" alt="Line" src="https://res.cloudinary.com/daqxbdqwb/image/upload/v1717086763/CrititLandingPageImageFolder/line-9_q23ey5.png" />
          <img className="h-[2727px] top-[1424px] absolute w-[1440px] left-0" alt="Line" src="https://res.cloudinary.com/daqxbdqwb/image/upload/v1717086764/CrititLandingPageImageFolder/line-10_f8fb4q.png" />
          <div className="inline-flex flex-col items-center justify-end gap-[64px] absolute top-0 left-0">
            <Header className="!flex-[0_0_auto] !bg-white" />
            <div className="inline-flex flex-col items-start gap-[120px] relative flex-[0_0_auto]">
              <div className="inline-flex items-center gap-[16px] relative flex-[0_0_auto]">
                <img className="relative w-[559px] h-[572px] object-cover" alt="Picture" src="https://res.cloudinary.com/daqxbdqwb/image/upload/v1717086764/CrititLandingPageImageFolder/picture_n8erzw.png" />
                <div className="inline-flex flex-col h-[214px] items-center justify-between relative flex-[0_0_auto]">
                  <p className="relative w-[557px] mt-[-1.00px] font-dekstop-heading-semi-bold-40 font-[number:var(--dekstop-heading-semi-bold-40-font-weight)] text-transparent text-[length:var(--dekstop-heading-semi-bold-40-font-size)] text-center tracking-[var(--dekstop-heading-semi-bold-40-letter-spacing)] leading-[var(--dekstop-heading-semi-bold-40-line-height)] [font-style:var(--dekstop-heading-semi-bold-40-font-style)]">
                    <span className="text-[#0e0e0e] font-dekstop-heading-semi-bold-40 [font-style:var(--dekstop-heading-semi-bold-40-font-style)] font-[number:var(--dekstop-heading-semi-bold-40-font-weight)] tracking-[var(--dekstop-heading-semi-bold-40-letter-spacing)] leading-[var(--dekstop-heading-semi-bold-40-line-height)] text-[length:var(--dekstop-heading-semi-bold-40-font-size)]">
                      Enhance your art career with reviews from the{" "}
                    </span>
                    <span className="text-[#3949dd] font-dekstop-heading-semi-bold-40 [font-style:var(--dekstop-heading-semi-bold-40-font-style)] font-[number:var(--dekstop-heading-semi-bold-40-font-weight)] tracking-[var(--dekstop-heading-semi-bold-40-letter-spacing)] leading-[var(--dekstop-heading-semi-bold-40-line-height)] text-[length:var(--dekstop-heading-semi-bold-40-font-size)]">
                      artistic community!
                    </span>
                  </p>         
                </div>
              </div>
              <div className="flex flex-col w-[1127px] items-start gap-[40px] relative flex-[0_0_auto]">
                <div className="inline-flex items-end gap-[130px] relative flex-[0_0_auto]">
                  <img className="relative w-[214px] h-[153.4px]" alt="Rectangle" src="https://res.cloudinary.com/daqxbdqwb/image/upload/v1717086765/CrititLandingPageImageFolder/rectangle-33_bscvwq.svg" />
                  <img className="relative w-[99px] h-[97.89px]" alt="Rectangle" src="https://res.cloudinary.com/daqxbdqwb/image/upload/v1717086771/CrititLandingPageImageFolder/rectangle-35_nnsrlb.svg" />
                </div>
                <div className="inline-flex flex-col items-center gap-[40px] relative flex-[0_0_auto] mr-[-3.00px]">
                  <p className="relative w-[1130px] mt-[-1.00px] font-dekstop-heading-semi-bold-64 font-[number:var(--dekstop-heading-semi-bold-64-font-weight)] text-transparent text-[length:var(--dekstop-heading-semi-bold-64-font-size)] text-center tracking-[var(--dekstop-heading-semi-bold-64-letter-spacing)] leading-[var(--dekstop-heading-semi-bold-64-line-height)] [font-style:var(--dekstop-heading-semi-bold-64-font-style)]">
                    <span className="text-[#0e0e0e] font-dekstop-heading-semi-bold-64 [font-style:var(--dekstop-heading-semi-bold-64-font-style)] font-[number:var(--dekstop-heading-semi-bold-64-font-weight)] tracking-[var(--dekstop-heading-semi-bold-64-letter-spacing)] leading-[var(--dekstop-heading-semi-bold-64-line-height)] text-[length:var(--dekstop-heading-semi-bold-64-font-size)]">
                      Your hub for honest artist feedback and{" "}
                    </span>
                    <span className="text-[#3949dd] font-dekstop-heading-semi-bold-64 [font-style:var(--dekstop-heading-semi-bold-64-font-style)] font-[number:var(--dekstop-heading-semi-bold-64-font-weight)] tracking-[var(--dekstop-heading-semi-bold-64-letter-spacing)] leading-[var(--dekstop-heading-semi-bold-64-line-height)] text-[length:var(--dekstop-heading-semi-bold-64-font-size)]">
                      community critique
                    </span>
                  </p>
                  <div className="inline-flex items-start gap-[245px] relative flex-[0_0_auto]">
                    <img className="relative w-[213px] h-[167.27px]" alt="Rectangle" src="https://res.cloudinary.com/daqxbdqwb/image/upload/v1717086771/CrititLandingPageImageFolder/rectangle-36_aftwbl.svg" />
                    <img className="relative w-[99px] h-[115.53px]" alt="Rectangle" src="https://res.cloudinary.com/daqxbdqwb/image/upload/v1717086765/CrititLandingPageImageFolder/rectangle-34_lcskf6.svg" />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-[1129px] items-start gap-[32px] relative flex-[0_0_auto]">
                <div className="inline-flex flex-col items-start gap-[16px] relative flex-[0_0_auto]">
                  <Label text={"For Artists"} divClassName={undefined} />
                  <div className="relative w-fit font-dekstop-heading-semi-bold-40 font-[number:var(--dekstop-heading-semi-bold-40-font-weight)] text-[#0e0e0e] text-[length:var(--dekstop-heading-semi-bold-40-font-size)] tracking-[var(--dekstop-heading-semi-bold-40-letter-spacing)] leading-[var(--dekstop-heading-semi-bold-40-line-height)] whitespace-nowrap [font-style:var(--dekstop-heading-semi-bold-40-font-style)]">
                    Visibility and Exposure
                  </div>
                </div>
                <div className="inline-flex items-center gap-[131px] relative flex-[0_0_auto]">
                  <img
                    className="w-[442px] h-[780.87px] relative object-cover"
                    alt="Picture"
                    src="https://res.cloudinary.com/daqxbdqwb/image/upload/v1717086765/CrititLandingPageImageFolder/picture-1_zrfgva.png"
                  />
                  <List />
                </div>
              </div>
              <div className="inline-flex flex-col items-start gap-[32px] relative flex-[0_0_auto]">
                <div className="inline-flex flex-col items-start justify-center gap-[16px] relative flex-[0_0_auto]">
                  <Label text={"For Artists"} divClassName={undefined} />
                  <div className="relative w-fit font-dekstop-heading-semi-bold-40 font-[number:var(--dekstop-heading-semi-bold-40-font-weight)] text-[#0e0e0e] text-[length:var(--dekstop-heading-semi-bold-40-font-size)] tracking-[var(--dekstop-heading-semi-bold-40-letter-spacing)] leading-[var(--dekstop-heading-semi-bold-40-line-height)] whitespace-nowrap [font-style:var(--dekstop-heading-semi-bold-40-font-style)]">
                    Personalized Recommendations:
                  </div>
                </div>
                <div className="inline-flex items-center gap-[65px] relative flex-[0_0_auto]">
                  <List />
                  <img
                    className="relative w-[621.28px] h-[451.34px] object-cover"
                    alt="Frame"
                    src="https://res.cloudinary.com/daqxbdqwb/image/upload/v1717086763/CrititLandingPageImageFolder/frame-3923-1_e8uv2a.png"
                  />
                </div>
              </div>
              <div className="inline-flex flex-col items-start gap-[32px] relative flex-[0_0_auto]">
                <div className="inline-flex flex-col items-start gap-[16px] relative flex-[0_0_auto]">
                  <div className="inline-flex items-start gap-[16px] relative flex-[0_0_auto]">
                    <Label text={"For Artists"} divClassName={undefined} />
                    <Label divClassName="!mr-[-10.00px] !ml-[-10.00px]" text="For art lovers" />
                    <Label divClassName="!mr-[-6.00px] !ml-[-6.00px]" text="For galleries" />
                  </div>
                  <div className="relative w-fit font-dekstop-heading-semi-bold-40 font-[number:var(--dekstop-heading-semi-bold-40-font-weight)] text-[#0e0e0e] text-[length:var(--dekstop-heading-semi-bold-40-font-size)] tracking-[var(--dekstop-heading-semi-bold-40-letter-spacing)] leading-[var(--dekstop-heading-semi-bold-40-line-height)] whitespace-nowrap [font-style:var(--dekstop-heading-semi-bold-40-font-style)]">
                    Engagement and Relationship Building:
                  </div>
                </div>
                <div className="flex w-[1130px] items-center gap-[104px] relative flex-[0_0_auto]">
                  <ListWrapper
                    divClassName="!flex-1 ![white-space:unset] !w-[unset]"
                    frameClassName="!self-stretch !flex !w-full"
                    text="Keep their audience updated"
                    text1="Inform about new event"
                    text2="Share your art"
                  />
                  <div className="inline-flex items-start gap-[24px] relative flex-[0_0_auto] mr-[-1.00px]">
                    <img className="relative flex-[0_0_auto] h-[400px]" alt="Frame" src="https://res.cloudinary.com/daqxbdqwb/image/upload/v1717086763/CrititLandingPageImageFolder/frame-17_uzueie.png" />
                    <img className="relative flex-[0_0_auto]" alt="Frame" src="https://res.cloudinary.com/daqxbdqwb/image/upload/v1717086763/CrititLandingPageImageFolder/frame-18_nsgx2z.png" />
                  </div>
                </div>
              </div>
              <div className="inline-flex flex-col items-start gap-[32px] relative flex-[0_0_auto]">
                <div className="inline-flex flex-col items-start gap-[16px] relative flex-[0_0_auto]">
                  <div className="inline-flex items-start gap-[16px] relative flex-[0_0_auto]">
                    <Label text="For Artists" divClassName={undefined} />
                    <Label divClassName="!mr-[-10.00px] !ml-[-10.00px]" text="For art lovers" />
                    <Label divClassName="!mr-[-6.00px] !ml-[-6.00px]" text="For galleries" />
                  </div>
                  <div className="relative w-fit font-dekstop-heading-semi-bold-40 font-[number:var(--dekstop-heading-semi-bold-40-font-weight)] text-[#0e0e0e] text-[length:var(--dekstop-heading-semi-bold-40-font-size)] tracking-[var(--dekstop-heading-semi-bold-40-letter-spacing)] leading-[var(--dekstop-heading-semi-bold-40-line-height)] whitespace-nowrap [font-style:var(--dekstop-heading-semi-bold-40-font-style)]">
                    Curated Collections:
                  </div>
                </div>
                <div className="inline-flex items-center gap-[52px] relative flex-[0_0_auto]">
                  <ListWrapper
                    divClassName="!flex-1 ![white-space:unset] !w-[unset]"
                    frameClassName="!self-stretch !flex !w-full"
                    text="Follow your favorite galleries"
                    text1="See collections from professionals"
                    text2="Promote your art"
                  />
                  <img className="relative w-[629.36px] h-[370px]" alt="Frame" src="https://res.cloudinary.com/daqxbdqwb/image/upload/v1717086763/CrititLandingPageImageFolder/frame-31-1_mkjwka.png" />
                </div>
              </div>
              <div className="inline-flex flex-col items-start gap-[32px] relative flex-[0_0_auto]">
                <p className="relative w-[1127px] mt-[-1.00px] font-dekstop-heading-semi-bold-40 font-[number:var(--dekstop-heading-semi-bold-40-font-weight)] text-transparent text-[length:var(--dekstop-heading-semi-bold-40-font-size)] tracking-[var(--dekstop-heading-semi-bold-40-letter-spacing)] leading-[var(--dekstop-heading-semi-bold-40-line-height)] [font-style:var(--dekstop-heading-semi-bold-40-font-style)]">
                  <span className="text-[#0e0e0e] font-dekstop-heading-semi-bold-40 [font-style:var(--dekstop-heading-semi-bold-40-font-style)] font-[number:var(--dekstop-heading-semi-bold-40-font-weight)] tracking-[var(--dekstop-heading-semi-bold-40-letter-spacing)] leading-[var(--dekstop-heading-semi-bold-40-line-height)] text-[length:var(--dekstop-heading-semi-bold-40-font-size)]">
                    Join a{" "}
                  </span>
                  <span className="text-[#3949dd] font-dekstop-heading-semi-bold-40 [font-style:var(--dekstop-heading-semi-bold-40-font-style)] font-[number:var(--dekstop-heading-semi-bold-40-font-weight)] tracking-[var(--dekstop-heading-semi-bold-40-letter-spacing)] leading-[var(--dekstop-heading-semi-bold-40-line-height)] text-[length:var(--dekstop-heading-semi-bold-40-font-size)]">
                    real community
                  </span>
                  <span className="text-[#0e0e0e] font-dekstop-heading-semi-bold-40 [font-style:var(--dekstop-heading-semi-bold-40-font-style)] font-[number:var(--dekstop-heading-semi-bold-40-font-weight)] tracking-[var(--dekstop-heading-semi-bold-40-letter-spacing)] leading-[var(--dekstop-heading-semi-bold-40-line-height)] text-[length:var(--dekstop-heading-semi-bold-40-font-size)]">
                    {" "}
                    with similar goals{" "}
                  </span>
                </p>
                <img className="relative w-[1127px] h-[668px] object-cover" alt="Frame" src="https://res.cloudinary.com/daqxbdqwb/image/upload/v1717086763/CrititLandingPageImageFolder/frame-3896-1_xl5eyx.png" />
              </div>
              <div className="inline-flex items-center gap-[91px] relative flex-[0_0_auto]">
                <div className="inline-flex flex-col items-start gap-[24px] relative flex-[0_0_auto]">
                  <div className="inline-flex flex-col items-start gap-[24px] relative flex-[0_0_auto]">
                    <Logo5 className="!relative !w-[213px] !h-[73px]" color="#0E0E0E" />
                    <div className="inline-flex flex-col items-start gap-[16px] relative flex-[0_0_auto]">
                      <p className="relative w-[711px] mt-[-1.00px] font-dekstop-heading-semi-bold-40 font-[number:var(--dekstop-heading-semi-bold-40-font-weight)] text-transparent text-[length:var(--dekstop-heading-semi-bold-40-font-size)] tracking-[var(--dekstop-heading-semi-bold-40-letter-spacing)] leading-[var(--dekstop-heading-semi-bold-40-line-height)] [font-style:var(--dekstop-heading-semi-bold-40-font-style)]">
                        <span className="text-[#0e0e0e] font-dekstop-heading-semi-bold-40 [font-style:var(--dekstop-heading-semi-bold-40-font-style)] font-[number:var(--dekstop-heading-semi-bold-40-font-weight)] tracking-[var(--dekstop-heading-semi-bold-40-letter-spacing)] leading-[var(--dekstop-heading-semi-bold-40-line-height)] text-[length:var(--dekstop-heading-semi-bold-40-font-size)]">
                          Get ready to{" "}
                        </span>
                        <span className="text-[#3949dd] font-dekstop-heading-semi-bold-40 [font-style:var(--dekstop-heading-semi-bold-40-font-style)] font-[number:var(--dekstop-heading-semi-bold-40-font-weight)] tracking-[var(--dekstop-heading-semi-bold-40-letter-spacing)] leading-[var(--dekstop-heading-semi-bold-40-line-height)] text-[length:var(--dekstop-heading-semi-bold-40-font-size)]">
                          elevate your creative journey
                        </span>
                        <span className="text-[#0e0e0e] font-dekstop-heading-semi-bold-40 [font-style:var(--dekstop-heading-semi-bold-40-font-style)] font-[number:var(--dekstop-heading-semi-bold-40-font-weight)] tracking-[var(--dekstop-heading-semi-bold-40-letter-spacing)] leading-[var(--dekstop-heading-semi-bold-40-line-height)] text-[length:var(--dekstop-heading-semi-bold-40-font-size)]">
                          &nbsp;
                        </span>
                      </p>
                      <p className="relative w-[711px] font-dekstop-body-regular-20 font-[number:var(--dekstop-body-regular-20-font-weight)] text-[#0e0e0e] text-[length:var(--dekstop-body-regular-20-font-size)] tracking-[var(--dekstop-body-regular-20-letter-spacing)] leading-[var(--dekstop-body-regular-20-line-height)] [font-style:var(--dekstop-body-regular-20-font-style)]">
                        Join our waiting list for the ultimate platform to advance your art career!
                      </p>
                    </div>
                  </div>
                  <div className="mb-60 inline-flex flex-col items-start gap-[16px] relative flex-[0_0_auto]">
                    <StateDefaultSizeS />
                  </div>
                </div>
                <img className="w-[328px] h-[430.98px] relative object-cover" alt="Picture" src="https://res.cloudinary.com/daqxbdqwb/image/upload/v1717086765/CrititLandingPageImageFolder/picture-2_fbmpah.png" />
              </div>
            </div>
            <Footer
              className="!flex-[0_0_auto]"
              override={<Facebooklogo className="!relative !w-[24px] !h-[24px]" color="white" />}
              socialMediaIcon={<Envelope1 className="!relative !w-[24px] !h-[24px]" color="white" />}
              socialMediaLinkedinlogo2Color="white"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
