/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { Palette19 } from "../../icons/Palette19";

export const List = (): JSX.Element => {
  return (
    <div className="flex flex-col w-[444px] items-start gap-[16px] p-[20px] relative bg-[#ffffff] rounded-[12px] border border-solid border-[#3949dd]">
      <div className="flex items-center gap-[16px] relative self-stretch w-full flex-[0_0_auto]">
        <Palette19 className="!relative !w-[32px] !h-[32px]" />
        <div className="relative flex-1 self-stretch mt-[-1.00px] font-dekstop-body-regular-20 font-[number:var(--dekstop-body-regular-20-font-weight)] text-[#0e0e0e] text-[length:var(--dekstop-body-regular-20-font-size)] tracking-[var(--dekstop-body-regular-20-letter-spacing)] leading-[var(--dekstop-body-regular-20-line-height)] whitespace-nowrap [font-style:var(--dekstop-body-regular-20-font-style)]">
          Share you art
        </div>
      </div>
      <div className="flex items-center gap-[16px] relative self-stretch w-full flex-[0_0_auto]">
        <Palette19 className="!relative !w-[32px] !h-[32px]" />
        <div className="relative flex-1 self-stretch mt-[-1.00px] font-dekstop-body-regular-20 font-[number:var(--dekstop-body-regular-20-font-weight)] text-[#0e0e0e] text-[length:var(--dekstop-body-regular-20-font-size)] tracking-[var(--dekstop-body-regular-20-letter-spacing)] leading-[var(--dekstop-body-regular-20-line-height)] whitespace-nowrap [font-style:var(--dekstop-body-regular-20-font-style)]">
          Create your unique profile
        </div>
      </div>
      <div className="flex h-[32px] items-center gap-[16px] relative self-stretch w-full">
        <Palette19 className="!relative !w-[32px] !h-[32px]" />
        <div className="relative flex-1 self-stretch mt-[-1.00px] font-dekstop-body-regular-20 font-[number:var(--dekstop-body-regular-20-font-weight)] text-[#0e0e0e] text-[length:var(--dekstop-body-regular-20-font-size)] tracking-[var(--dekstop-body-regular-20-letter-spacing)] leading-[var(--dekstop-body-regular-20-line-height)] whitespace-nowrap [font-style:var(--dekstop-body-regular-20-font-style)]">
          Increase your visibility
        </div>
      </div>
    </div>
  );
};
