/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { JOIN_US_SURVEY_LINK } from "../../constants";

export const StateDefaultSizeS = (): JSX.Element => {
  return (    
    <a href={JOIN_US_SURVEY_LINK}>
      <div className="flex w-[200px] h-[46px] items-center justify-center gap-[8px] px-[32px] py-[8px] relative bg-blue rounded-[32px]">
        <button name="JoinUs" style={{textDecoration: 'none', backgroundColor: 'transparent', border: 'none'}}>
            <div className="relative w-fit font-dekstop-body-regular-20 font-[number:var(--dekstop-body-regular-20-font-weight)] text-white text-[length:var(--dekstop-body-regular-20-font-size)] tracking-[var(--dekstop-body-regular-20-letter-spacing)] leading-[var(--dekstop-body-regular-20-line-height)] whitespace-nowrap [font-style:var(--dekstop-body-regular-20-font-style)]">
              Join us
            </div>
        </button>  
      </div>
    </a>
  );
};
