import PropTypes from "prop-types";
import React from "react";
import { Envelope } from "../../icons/Envelope";
import { Facebooklogo } from "../../icons/Facebooklogo";
import { Linkedinlogo2 } from "../../icons/Linkedinlogo2";
import { Logo5 } from "../../icons/Logo5";
import { Link } from "../Link";
import { SocialMedia } from "../SocialMedia";

interface Props {
  className: any;
  socialMediaLinkedinlogo2Color: string;
  socialMediaIcon: JSX.Element;
  override: JSX.Element;
}

export const Footer = ({
  className,
  socialMediaLinkedinlogo2Color,
  socialMediaIcon = <Envelope className="!relative !w-[24px] !h-[24px]" />,
  override = <Facebooklogo className="!relative !w-[24px] !h-[24px]" color="white" />,
}: Props): JSX.Element => {
  return (
    <div className={`flex flex-col w-[1440px] items-start gap-[120px] p-[40px] relative bg-blue ${className}`}>
      <div className="flex items-center gap-[48px] relative self-stretch w-full flex-[0_0_auto]">
        <div className="flex flex-col items-start gap-[16px] relative flex-1 grow">
          <Logo5 className="!relative !w-[237px] !h-[79px]" color="white" />
          <div className="relative self-stretch font-dekstop-heading-semi-bold-32 font-[number:var(--dekstop-heading-semi-bold-32-font-weight)] text-white text-[length:var(--dekstop-heading-semi-bold-32-font-size)] tracking-[var(--dekstop-heading-semi-bold-32-letter-spacing)] leading-[var(--dekstop-heading-semi-bold-32-line-height)] [font-style:var(--dekstop-heading-semi-bold-32-font-style)]">
            Your supportive art community
          </div>
        </div>
        <div className="flex-col items-start gap-[16px] flex-[0_0_auto] inline-flex relative">
          <SocialMedia
            divClassName="!text-white"
            icon={<Linkedinlogo2 className="!relative !w-[24px] !h-[24px]" color="white" />}
            linkedinlogo2Color={socialMediaLinkedinlogo2Color}
            stte="default"
            text="Linkedin"
          />
          <a href="mailto:info@kritit.com">
            <SocialMedia divClassName="!text-white" icon={socialMediaIcon} stte="default" text="Gmail" linkedinlogo2Color={""} />
          </a>
          <SocialMedia divClassName="!text-white" icon={override} stte="default" text="Facebook" linkedinlogo2Color={""} />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center gap-[16px] relative self-stretch w-full flex-[0_0_auto]">
        <div className="flex items-start justify-center gap-[32px] relative self-stretch w-full flex-[0_0_auto]">
          <div className="inline-flex items-start gap-[32px] relative flex-[0_0_auto]">
            <Link
              className="!flex-[0_0_auto] ![justify-content:unset]"
              divClassName="!text-white ![text-align:unset]"
              state="default"
              text="Terms of use"
            />
            <Link
              className="!flex-[0_0_auto] ![justify-content:unset]"
              divClassName="!text-white"
              state="default"
              text="About us"
            />
          </div>
        </div>
        <p className="relative w-fit font-dekstop-body-regular-16 font-[number:var(--dekstop-body-regular-16-font-weight)] text-white text-[length:var(--dekstop-body-regular-16-font-size)] tracking-[var(--dekstop-body-regular-16-letter-spacing)] leading-[var(--dekstop-body-regular-16-line-height)] whitespace-nowrap [font-style:var(--dekstop-body-regular-16-font-style)]">
          © 2024 All Rights Reserved
        </p>
      </div>
    </div>
  );
};

Footer.propTypes = {
  socialMediaLinkedinlogo2Color: PropTypes.string,
};
