/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const Logo3 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="38"
      viewBox="0 0 109 38"
      width="109"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M108.312 11.3162V16.6115H93.0049V11.3162H108.312ZM96.4799 5.22656H103.529V28.923C103.529 29.5739 103.629 30.0814 103.827 30.4454C104.026 30.7984 104.302 31.0467 104.655 31.1901C105.019 31.3335 105.438 31.4052 105.912 31.4052C106.243 31.4052 106.574 31.3776 106.905 31.3225C107.236 31.2563 107.49 31.2066 107.666 31.1735L108.775 36.4192C108.422 36.5295 107.925 36.6564 107.286 36.7998C106.646 36.9542 105.868 37.048 104.952 37.0811C103.253 37.1473 101.764 36.9211 100.484 36.4026C99.2158 35.8841 98.2285 35.0788 97.5224 33.9867C96.8164 32.8945 96.4689 31.5155 96.4799 29.8497V5.22656Z"
        fill="#0E0E0E"
      />
      <path
        d="M82.0958 36.7337V11.3163H89.1452V36.7337H82.0958ZM85.6371 8.03982C84.589 8.03982 83.6899 7.69232 82.9398 6.99731C82.2006 6.29127 81.8311 5.44733 81.8311 4.46549C81.8311 3.49469 82.2006 2.66178 82.9398 1.96678C83.6899 1.26074 84.589 0.907715 85.6371 0.907715C86.6851 0.907715 87.5787 1.26074 88.3178 1.96678C89.068 2.66178 89.443 3.49469 89.443 4.46549C89.443 5.44733 89.068 6.29127 88.3178 6.99731C87.5787 7.69232 86.6851 8.03982 85.6371 8.03982Z"
        fill="#0E0E0E"
      />
      <path
        d="M77.5035 11.3162V16.6115H62.1968V11.3162H77.5035ZM65.6718 5.22656H72.7212V28.923C72.7212 29.5739 72.8205 30.0814 73.019 30.4454C73.2176 30.7984 73.4934 31.0467 73.8464 31.1901C74.2105 31.3335 74.6297 31.4052 75.1041 31.4052C75.435 31.4052 75.766 31.3776 76.0969 31.3225C76.4279 31.2563 76.6816 31.2066 76.8581 31.1735L77.9668 36.4192C77.6138 36.5295 77.1174 36.6564 76.4775 36.7998C75.8377 36.9542 75.0599 37.048 74.1443 37.0811C72.4454 37.1473 70.9561 36.9211 69.6764 36.4026C68.4077 35.8841 67.4204 35.0788 66.7143 33.9867C66.0083 32.8945 65.6608 31.5155 65.6718 29.8497V5.22656Z"
        fill="#0E0E0E"
      />
      <path
        d="M51.2877 36.7337V11.3163H58.3371V36.7337H51.2877ZM54.8289 8.03982C53.7809 8.03982 52.8818 7.69232 52.1317 6.99731C51.3925 6.29127 51.0229 5.44733 51.0229 4.46549C51.0229 3.49469 51.3925 2.66178 52.1317 1.96678C52.8818 1.26074 53.7809 0.907715 54.8289 0.907715C55.877 0.907715 56.7706 1.26074 57.5097 1.96678C58.2599 2.66178 58.6349 3.49469 58.6349 4.46549C58.6349 5.44733 58.2599 6.29127 57.5097 6.99731C56.7706 7.69232 55.877 8.03982 54.8289 8.03982Z"
        fill="#0E0E0E"
      />
      <path
        d="M32.2202 36.7339V11.3164H39.0545V15.7513H39.3192C39.7826 14.1737 40.5603 12.9823 41.6525 12.1769C42.7446 11.3606 44.0023 10.9524 45.4254 10.9524C45.7784 10.9524 46.159 10.9745 46.5672 11.0186C46.9753 11.0627 47.3339 11.1234 47.6428 11.2006V17.4557C47.3118 17.3564 46.854 17.2681 46.2693 17.1909C45.6846 17.1137 45.1496 17.0751 44.6642 17.0751C43.6272 17.0751 42.7005 17.3012 41.8841 17.7535C41.0788 18.1948 40.439 18.8126 39.9646 19.6069C39.5013 20.4012 39.2696 21.3168 39.2696 22.3538V36.7339H32.2202Z"
        fill="#0E0E0E"
      />
      <path
        d="M0.225586 36.7339V2.84399H7.39079V17.7867H7.83758L20.0333 2.84399H28.6216L16.0453 18.0183L28.7706 36.7339H20.1988L10.9155 22.8007L7.39079 27.1031V36.7339H0.225586Z"
        fill="#0E0E0E"
      />
    </svg>
  );
};
