/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  color: string;
  className: any;
}

export const Logo5 = ({ color = "#0E0E0E", className }: Props): JSX.Element => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="80"
      viewBox="0 0 238 80"
      width="238"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M236.214 23.2245V34.7854H202.794V23.2245H236.214ZM210.381 9.92944H225.772V61.6645C225.772 63.0855 225.989 64.1935 226.423 64.9883C226.856 65.759 227.458 66.3009 228.229 66.614C229.024 66.9271 229.939 67.0837 230.975 67.0837C231.698 67.0837 232.42 67.0235 233.143 66.9031C233.865 66.7585 234.419 66.6502 234.805 66.5779L237.225 78.0304C236.455 78.2713 235.371 78.5483 233.974 78.8614C232.577 79.1986 230.879 79.4033 228.879 79.4755C225.17 79.6201 221.919 79.1263 219.124 77.9943C216.355 76.8623 214.199 75.1041 212.657 72.7196C211.116 70.3352 210.357 67.3246 210.381 63.6877V9.92944Z"
        fill={color}
      />
      <path
        d="M178.976 78.7168V23.2244H194.367V78.7168H178.976ZM186.708 16.0711C184.42 16.0711 182.456 15.3124 180.819 13.795C179.205 12.2536 178.398 10.4111 178.398 8.26749C178.398 6.14799 179.205 4.32955 180.819 2.81219C182.456 1.27073 184.42 0.5 186.708 0.5C188.996 0.5 190.947 1.27073 192.561 2.81219C194.199 4.32955 195.018 6.14799 195.018 8.26749C195.018 10.4111 194.199 12.2536 192.561 13.795C190.947 15.3124 188.996 16.0711 186.708 16.0711Z"
        fill={color}
      />
      <path
        d="M168.95 23.2245V34.7854H135.53V23.2245H168.95ZM143.117 9.92944H158.509V61.6645C158.509 63.0855 158.725 64.1935 159.159 64.9883C159.593 65.759 160.195 66.3009 160.965 66.614C161.76 66.9271 162.676 67.0837 163.711 67.0837C164.434 67.0837 165.156 67.0235 165.879 66.9031C166.602 66.7585 167.156 66.6502 167.541 66.5779L169.962 78.0304C169.191 78.2713 168.107 78.5483 166.71 78.8614C165.313 79.1986 163.615 79.4033 161.616 79.4755C157.906 79.6201 154.655 79.1263 151.861 77.9943C149.091 76.8623 146.935 75.1041 145.394 72.7196C143.852 70.3352 143.093 67.3246 143.117 63.6877V9.92944Z"
        fill={color}
      />
      <path
        d="M111.712 78.7168V23.2244H127.103V78.7168H111.712ZM119.444 16.0711C117.155 16.0711 115.192 15.3124 113.554 13.795C111.941 12.2536 111.134 10.4111 111.134 8.26749C111.134 6.14799 111.941 4.32955 113.554 2.81219C115.192 1.27073 117.155 0.5 119.444 0.5C121.732 0.5 123.683 1.27073 125.297 2.81219C126.934 4.32955 127.753 6.14799 127.753 8.26749C127.753 10.4111 126.934 12.2536 125.297 13.795C123.683 15.3124 121.732 16.0711 119.444 16.0711Z"
        fill={color}
      />
      <path
        d="M70.0811 78.7169V23.2245H85.0025V32.9068H85.5806C86.5922 29.4626 88.2903 26.8614 90.6749 25.1032C93.0594 23.3208 95.8053 22.4297 98.9124 22.4297C99.6832 22.4297 100.514 22.4779 101.405 22.5742C102.297 22.6705 103.079 22.803 103.754 22.9716V36.6279C103.031 36.4112 102.032 36.2185 100.755 36.0499C99.4784 35.8813 98.3102 35.797 97.2504 35.797C94.9863 35.797 92.9631 36.2907 91.1807 37.2782C89.4224 38.2416 88.0254 39.5904 86.9897 41.3246C85.978 43.0587 85.4722 45.0578 85.4722 47.3218V78.7169H70.0811Z"
        fill={color}
      />
      <path
        d="M0.225586 78.7169V4.72705H15.8697V37.3505H16.8452L43.4726 4.72705H62.2238L34.7654 37.8563L62.549 78.7169H43.8339L23.5653 48.2972L15.8697 57.6905V78.7169H0.225586Z"
        fill={color}
      />
    </svg>
  );
};

Logo5.propTypes = {
  color: PropTypes.string,
};
