/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { Logo3 } from "../../icons/Logo3";
import { Link } from "../Link";
import { StateDefaultSizeS } from "../StateDefaultSizeS";

interface Props {
  className: any;
}

export const Header = ({ className }: Props): JSX.Element => {
  return (
    <div
      className={`flex w-[1440px] items-center justify-center gap-[371px] px-[48px] py-[24px] relative bg-[#ffffff] ${className}`}
    >
      <Logo3 className="!relative !w-[108.55px] !h-[36.18px]" />
      <div className="inline-flex items-center gap-[32px] relative flex-[0_0_auto]">
        <Link className="!flex-[0_0_auto]" state="default" text="About us" />
        <Link className="!flex-[0_0_auto]" state="default" text="Features" />
      </div>
      <StateDefaultSizeS />
    </div>
  );
};
