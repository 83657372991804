/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Linkedinlogo2 } from "../../icons/Linkedinlogo2";

interface Props {
  stte: "hover" | "press" | "default";
  linkedinlogo2Color: string;
  divClassName: any;
  text: string;
  icon: JSX.Element;
}

export const SocialMedia = ({
  stte,
  linkedinlogo2Color = "#0E0E0E",
  divClassName,
  text = "About us",
  icon = <Linkedinlogo2 className="!relative !w-[24px] !h-[24px]" color={linkedinlogo2Color} />,
}: Props): JSX.Element => {
  return (
    <div className="inline-flex items-center gap-[8px] h-[36px] relative">
      {icon}
      <div
        className={`font-dekstop-link-regular-24 w-fit tracking-[var(--dekstop-link-regular-24-letter-spacing)] [font-style:var(--dekstop-link-regular-24-font-style)] text-[length:var(--dekstop-link-regular-24-font-size)] relative font-[number:var(--dekstop-link-regular-24-font-weight)] text-center whitespace-nowrap leading-[var(--dekstop-link-regular-24-line-height)] ${
          stte === "hover" ? "text-blue" : stte === "press" ? "text-dark-blue" : "text-[#0e0e0e]"
        } ${divClassName}`}
      >
        {text}
      </div>
    </div>
  );
};

SocialMedia.propTypes = {
  stte: PropTypes.oneOf(["hover", "press", "default"]),
  linkedinlogo2Color: PropTypes.string,
  text: PropTypes.string,
};
