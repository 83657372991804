/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  divClassName: any;
  text: string;
}

export const Label = ({ divClassName, text = "For Artists" }: Props): JSX.Element => {
  return (
    <div className="flex w-[144px] h-[40px] items-center justify-center gap-[8px] px-[32px] py-[8px] relative bg-white rounded-[32px] border-2 border-solid border-blue">
      <div
        className={`relative w-fit mt-[-2.00px] font-dekstop-body-regular-16 font-[number:var(--dekstop-body-regular-16-font-weight)] text-blue text-[length:var(--dekstop-body-regular-16-font-size)] tracking-[var(--dekstop-body-regular-16-letter-spacing)] leading-[var(--dekstop-body-regular-16-line-height)] whitespace-nowrap [font-style:var(--dekstop-body-regular-16-font-style)] ${divClassName}`}
      >
        {text}
      </div>
    </div>
  );
};

Label.propTypes = {
  text: PropTypes.string,
};
