/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Palette19 } from "../../icons/Palette19";

interface Props {
  frameClassName: any;
  divClassName: any;
  text: string;
  text1: string;
  text2: string;
}

export const ListWrapper = ({
  frameClassName,
  divClassName,
  text = "Share you art",
  text1 = "Create your unique profile",
  text2 = "Increase your visibility",
}: Props): JSX.Element => {
  return (
    <div className="flex flex-col w-[443px] items-start gap-[16px] p-[20px] relative bg-[#ffffff] rounded-[12px] border border-solid border-[#3949dd]">
      <div className={`inline-flex items-center gap-[16px] relative flex-[0_0_auto] ${frameClassName}`}>
        <Palette19 className="!relative !w-[32px] !h-[32px]" />
        <div
          className={`relative w-fit font-dekstop-body2-regular-20 font-[number:var(--dekstop-body2-regular-20-font-weight)] text-black text-[length:var(--dekstop-body2-regular-20-font-size)] tracking-[var(--dekstop-body2-regular-20-letter-spacing)] leading-[var(--dekstop-body2-regular-20-line-height)] whitespace-nowrap [font-style:var(--dekstop-body2-regular-20-font-style)] ${divClassName}`}
        >
          {text}
        </div>
      </div>
      <div className="inline-flex items-center gap-[16px] relative flex-[0_0_auto]">
        <Palette19 className="!relative !w-[32px] !h-[32px]" />
        <div className="relative w-fit font-dekstop-body2-regular-20 font-[number:var(--dekstop-body2-regular-20-font-weight)] text-black text-[length:var(--dekstop-body2-regular-20-font-size)] tracking-[var(--dekstop-body2-regular-20-letter-spacing)] leading-[var(--dekstop-body2-regular-20-line-height)] whitespace-nowrap [font-style:var(--dekstop-body2-regular-20-font-style)]">
          {text1}
        </div>
      </div>
      <div className="inline-flex items-center gap-[16px] relative flex-[0_0_auto]">
        <Palette19 className="!relative !w-[32px] !h-[32px]" />
        <div className="relative w-fit font-dekstop-body2-regular-20 font-[number:var(--dekstop-body2-regular-20-font-weight)] text-black text-[length:var(--dekstop-body2-regular-20-font-size)] tracking-[var(--dekstop-body2-regular-20-letter-spacing)] leading-[var(--dekstop-body2-regular-20-line-height)] whitespace-nowrap [font-style:var(--dekstop-body2-regular-20-font-style)]">
          {text2}
        </div>
      </div>
    </div>
  );
};

ListWrapper.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
};
